import {computed, nextTick, onBeforeMount, onUnmounted, ref, shallowRef} from "vue";
import config from "@/config.js";
import * as SDriver from "@elastic/search-ui";
import {eventBus, useHelpers} from "@/Composables";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import _ from "lodash";
import {router} from "@inertiajs/vue3";
import queryString from "@elastic/search-ui/lib/esm/queryString.js";
import route from "ziggy-js";
import { Ziggy } from "@/ziggy";
const { SearchDriver } = SDriver;

let globalState = {};
let globalDriver = {};
let globalResults = {};
let globalFacets = {};


export default function useAppSearchGeneric(settings, isGlobal = false, key = null){

    const searchIsWritingHistory = ref(false);
    const searchIsFinished = ref(false);

    function stateAdapter(){
        if(isGlobal){

            if(!globalState[key]){
                globalState[key] = ref({});
            }

            settings.initialState = globalState[key].value;

            return computed({
                get: () => globalState[key].value,
                set: (newValue) =>  globalState[key].value = newValue,
            });

        }
        return ref({});
    }

    function driverAdapter(){
        if(isGlobal){
            if(!globalDriver[key]){
                globalDriver[key] = null;
            }

            return computed({
                get: () => globalDriver[key],
                set: (newValue) =>  globalDriver[key] = newValue,
            });
        }
        return shallowRef(null);
    }

    let searchState = stateAdapter();
    let searchDriver = driverAdapter();

    function isNumericString(num) {
        return !isNaN(num);
    }
    function toSingleValue(val) {
        return Array.isArray(val) ? val[val.length - 1] : val;
    }
    function toSingleValueInteger(num) {
        return toInteger(toSingleValue(num));
    }
    function toInteger(num) {
        if (!isNumericString(num))
            return;
        return parseInt(num, 10);
    }

    function parseFiltersFromQueryParams(queryParams) {
        return queryParams.filters;
    }
    function parseCurrentFromQueryParams(queryParams) {
        return toSingleValueInteger(queryParams.current);
    }
    function parseSearchTermFromQueryParams(queryParams) {
        return toSingleValue(queryParams.q);
    }
    function parseOldSortFromQueryParams(queryParams) {
        const sortField = toSingleValue(queryParams["sort-field"]);
        const sortDirection = toSingleValue(queryParams["sort-direction"]);
        if (sortField)
            return [sortField, sortDirection];
        return [];
    }
    function parseSizeFromQueryParams(queryParams) {
        return toSingleValueInteger(queryParams.size);
    }
    function parseSortFromQueryParams(queryParams) {
        return queryParams["sort"];
    }

    function paramsToState(queryParams) {
        const state = {
            current: parseCurrentFromQueryParams(queryParams),
            filters: parseFiltersFromQueryParams(queryParams),
            searchTerm: parseSearchTermFromQueryParams(queryParams),
            resultsPerPage: parseSizeFromQueryParams(queryParams),
            sortField: parseOldSortFromQueryParams(queryParams)[0],
            sortDirection: parseOldSortFromQueryParams(queryParams)[1],
            sortList: parseSortFromQueryParams(queryParams)
        };
        return Object.keys(state).reduce((acc, key) => {
            const value = state[key];
            if (value)
                acc[key] = value;
            return acc;
        }, {});
    }

    function searchClearFilters(preserve = []) {
        searchDriver.value.getActions().clearFilters(preserve);
    }

    function mountSearchDriver() {
        eventBus.on('search-history-written', () => searchIsWritingHistory.value = false);
        if(isGlobal && globalDriver[key]){
            return;
        }

        settings.onSearch ??= (requestState, queryConfig, next) => {
            searchIsWritingHistory.value = true;
            searchIsFinished.value = false;
            return next(requestState, queryConfig);
        };

        settings.apiConnector = new AppSearchAPIConnector(settings.apiConnector);
        searchDriver.value = new SearchDriver(settings);

        searchDriver.value.subscribeToStateChanges((newState) => {
            searchIsFinished.value = (newState.isLoading === false && searchState.value?.isLoading === true);
            searchState.value = _.omitBy(newState, (value, key) => ['rawResponse'].includes(key));
        });

        if(settings.mountSearchDriver){
            settings.mountSearchDriver(searchDriver);
        }
    }

    function unmountSearchDriver() {
        eventBus.off('search-history-written', () => searchIsWritingHistory.value = false);
        if(!isGlobal){
            searchDriver.value.tearDown();
        }
    }

    function writeUrl(url, {replaceUrl}) {
        // Convert the provided query string into a parameters object
        const newParams = Object.fromEntries(new URLSearchParams(url))

        // If we're rendering results (i.e. there's a query param provided), allow the configuration
        // to specify a base route to render the results on instead of the current route.
        // e.g. blog posts have a sidebar search box, but we want to render results at the index URL
        const newRoute = newParams.q && settings.baseRoute ? settings.baseRoute : route().current();

        // The current route might have required parameters, so re-rendering needs to include those
        // bindings in the route parameters, along with the new search parameters
        // e.g. a blog post includes an `{article}` param of the post slug
        const bindings = Ziggy.routes[newRoute].bindings ?? {}
        const routeParams = Object.fromEntries(Object.entries(route().params).filter(([k]) => Object.keys(bindings).includes(k)))

        router.visit(route(newRoute, { ...routeParams, ...newParams }), {
            replace: replaceUrl,
            preserveScroll: true,
            preserveState: true,
            preserveQuery: false,
            only: ["referer"],
            onFinish(){
                searchIsWritingHistory.value = false;
            }
        });
    }

    function urlToState(url){
        let state = paramsToState(queryString.parse(url));
        console.log('url to state',state);
        state.resultsPerPage = Math.min(state.resultsPerPage ?? 20, 50);
        state.current ??= 1;
        state.current = (state.current * state.resultsPerPage) > 10000 ? 1 : state.current;
        return state;
    }

    settings.routingOptions ??= {};
    settings.routingOptions.writeUrl ??= writeUrl;
    settings.routingOptions.urlToState ??= urlToState;

    settings.searchQuery ??= {};
    settings.searchQuery.filters ??= [];
    settings.searchQuery.filters = settings.searchQuery.filters.concat(_.get(settings, 'filters', []));

    onBeforeMount(mountSearchDriver);

    onUnmounted(unmountSearchDriver);

    return {
        writeUrl,
        urlToState,
        mountSearchDriver,
        unmountSearchDriver,
        searchSettings : settings,
        searchDriver,
        searchState,
        searchClearFilters,
        searchSortOptions: computed(() => settings.sortOptions),
        searchCurrentPage: computed({
            get() {
                return searchState.value.current;
            },
            set(newValue) {
                searchDriver.value.getActions().setCurrent(newValue);
            },
        }),
        searchTerm: computed({
            get() {
                return searchState.value.searchTerm;
            },
            set(newValue) {
                //SEC-2455
                console.log('debounce',config.search.debounce)
                eventBus.emit("catalog:product-search-click", newValue);
                searchDriver.value.getActions().setSearchTerm(newValue, {
                    shouldClearFilters: false,
                    debounce: config.search.debounce,
                });
            },
        }),
        searchTermQuick: computed({
            get() {
                return searchState.value.searchTerm;
            },
            set(newValue) {
                //SEC-2455
                eventBus.emit("catalog:product-search-click", newValue);
                searchDriver.value.getActions().setSearchTerm(newValue, {
                    shouldClearFilters: false,
                    debounce: 0,
                });
            },
        }),
        searchResultsPerPage: computed({
            get() {
                return searchState.value.resultsPerPage;
            },
            set(newValue) {
                searchDriver.value.getActions().setResultsPerPage(Math.min(newValue, 50));
            },
        }),
        searchHasError:computed(()=> (searchState.value?.error !== '')),
        searchResultsTerm: computed(()=> searchState.value.resultSearchTerm ?? ''),
        searchHasResults: computed(() => !!searchState.value.totalResults),
        searchWasSearched: computed(() => !!searchState.value.wasSearched),
        searchIsLoading: computed(() => searchState.value.isLoading || (settings.trackUrlState ? searchIsWritingHistory.value : false)),
        searchIsFinished,
        searchIsWritingHistory,
        searchResults: computed(()=>searchState.value.results?.map(useHelpers().cardValue)),
        searchTotalResults: computed(() => searchState.value.totalResults),
        searchRealTotalResults: computed(() => _.get(searchState.value.facets, "count.0.data.0.count", searchState.value.totalResults)),
        searchReset() {
            searchDriver.value.getActions().setSearchTerm("",{shouldClearFilters: true, debounce: config.search.debounce});
        },
        searchTrackClick(documentId) {
            searchDriver
                .value
                .getActions()
                .trackClickThrough(
                    documentId,
                    settings.searchQuery?.analytics?.tags ?? []
                );
        },
        searchRefresh: () => {
            searchDriver
                .value
                .getActions()
                .setSearchTerm(searchState.value.searchTerm,{
                    shouldClearFilters: false,
                    refresh: true,
                    debounce: config.search.debounce,
                });
        },
        searchSortBy: computed({
            get() {
                let sort = {};
                if (searchState.value.sortField) {
                    sort = _.get(settings,'sortOptions',[]).find((option) => {
                        return _.isEqual(option.value, {
                            field: searchState.value.sortField,
                            direction: searchState.value.sortDirection,
                        });
                    });
                }else{
                    sort = _.get(settings,'sortOptions.0',{});
                }

                return sort;
            },
            set(newValue) {
                searchDriver
                    .value
                    .getActions()
                    .setSort(newValue?.value.field, newValue?.value.direction);
            },
        }),
        searchAddFilter(facet, value, type = "any") {
            searchDriver.value.getActions().addFilter(facet, value, type);
        },
        searchSetFilter(facet, value, type = "any", clear = false, preserve = []){
            if (clear) {
                searchClearFilters(preserve);
            }
            searchDriver.value.getActions().setFilter(facet, value, type);
        },
        searchRemoveFilter(facet, value, type = "any", clear = false, preserve = []){
            if (clear) {
                searchClearFilters(preserve);
            }
            searchDriver.value.getActions().removeFilter(facet, value, type);
        },
        searchAppliedFilters: computed(() => {
            let filters = {};

            for (const [key, value] of Object.entries(settings.searchQuery.facets)) {
                filters[key] = _.get(searchState.value,'filters', []).find((value) => value.field === key)?.values?.map((value) => ({value})) ?? [];
            }

            return filters;
        }),
        searchFacets: computed(()=>searchState.value.facets),
    };

}

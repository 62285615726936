<template>
    <footer
        class="bg-white border-t border-gray-150 py-8 text-center text-gray at1024:pb-[18px] at1024:pt-24 at1024:text-left print:hidden"
        data-inert="true"
        id="footer"
    >
        <div class="wrap wrap-huge at1024:flex at1024:flex-wrap at1024:justify-between">
            <div class="at1024:w-2/5">
                <BaseSvg name="seclock-icon" class="mb-6 w-16" aria-hidden="true" />
                <p class="sr-only at1024:max-w-[500px] at1024:not-sr-only at1024:text-sm">
                    {{ $t('SECLOCK is the premier commercial door hardware distributor in the US, providing same-day access to all major product brands. We are wholesale-only and our technical team has the expertise to find solutions for any size project.') }}
                </p>
                <h3 class="sr-only">{{ $t('Follow Us') }}</h3>
                <ul class="inline-flex gap-3 sr-only at1024:not-sr-only at1024:mt-[18px]">
                    <li v-for="link in socialLinks">
                        <a
                            :href="link.href"
                            target="_blank"
                            class="bg-gray-175 flex group h-9 items-center justify-center rounded-full w-9">
                            <BaseSvg :name="link.icon" class="fill-gray-550 w-4 group-hover:fill-black" aria-hidden="true" />
                            <span class="sr-only">{{ $t(link.title) }}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <nav class="sr-only at1024:gap-4 at1024:grid at1024:grid-cols-4 at1024:not-sr-only at1024:pt-2 at1024:w-[55%]" :aria-label="$t('Footer navigation')">
                <div v-for="navSection in footerLinks">
                    <h3 class="font-medium text-gray-400">{{ $t(navSection.title) }}</h3>
                    <ul class="font-medium mt-3 space-y-2 text-sm">
                        <li v-for="link in navSection.links">
                            <a
                                v-if="link.openInNewTab"
                                href="https://support.seclock.com"
                                target="_blank"
                                class="hover:underline">{{ $t('Articles') }}
                                <BaseSvg name="OpenInNew" class="h-4 -translate-y-px w-4" aria-hidden="true" />
                            </a>
                            <Link v-else :href="route(link.route)" class="hover:underline">
                                {{ $t(link.title) }}
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>

            <div
                class="border-gray-150 border-t max-w-2xl mx-auto pt-[18px] text-xs at1024:flex at1024:justify-between at1024:max-w-none at1024:mt-6 at1024:text-sm at1024:w-full">
                <ul class="inline-flex font-medium space-x-4">
                    <li v-for="link in legalLinks">
                        <a v-if="link.isAnchor" :href="link.route" target="_blank" class="block leading-tight at768:hover:underline">
                            {{ $t(link.title) }}
                            <BaseSvg name="OpenInNew" class="h-4 -translate-y-px w-4" aria-hidden="true" />
                        </a>
                        <Link v-else :href="route(link.route)" class="block leading-tight at768:hover:underline">
                            {{ $t(link.title) }}
                        </Link>
                    </li>
                    <li>
                    </li>
                </ul>
                <p class="mt-6 at1024:mt-0">©️ {{ new Date().getFullYear() }} {{ $t('SECLOCK') }}. All rights reserved.</p>
            </div>
        </div>
    </footer>
</template>

<script setup>
import {BaseSvg} from "@/Components";

const legalLinks = [
    {
        title: 'Privacy Policy',
        route: 'https://www.iubenda.com/privacy-policy/7865490',
        isAnchor: true
    },
    {
        title: 'Terms & Conditions',
        route: 'web.terms-and-conditions',
    },
    {
        title: 'Systems Status',
        route: 'http://status.seclock.com/',
        isAnchor: true
    },
];

const footerLinks = [
    {
        title: 'Company',
        links: [
            {
                title: 'About',
                route: 'web.about',
                openInNewTab: false
            },
            {
                title: 'Brand',
                route: 'web.brand',
                openInNewTab: false
            },
            {
                title: 'Careers',
                route: 'web.careers.index',
                openInNewTab: false
            }
        ]
    },
    {
        title: 'Catalog',
        links: [
            {
                title: 'Products',
                route: 'web.catalog.products.index',
                openInNewTab: false
            },
            {
                title: 'Manufacturers',
                route: 'web.catalog.manufacturers.index',
                openInNewTab: false
            },
            {
                title: 'List Price Books',
                route: 'web.catalog.price-books.index',
                openInNewTab: false
            },
            {
                title: 'Resources',
                route: 'web.catalog.resources.index',
                openInNewTab: false
            },
            {
                title: 'Value Center',
                route: 'web.catalog.tags.value-center',
                openInNewTab: false
            }
        ]
    },
    {
        title: 'Support',
        links: [
            {
                title: 'Articles',
                route: 'https://support.seclock.com',
                openInNewTab: true
            },
            {
                title: 'Contact',
                route: 'web.contact',
                openInNewTab: false
            },
            {
                title: 'Locations',
                route: 'web.locations',
                openInNewTab: false
            }
        ]
    },
    {
        title: 'Learn',
        links: [
            {
                title: 'Blog',
                route: 'web.education.index',
                openInNewTab: false
            },
            {
                title: 'Tutorials',
                route: 'web.support.tutorials.index',
                openInNewTab: false
            },
            {
                title: 'Webinars',
                route: 'web.webinars',
                openInNewTab: false
            }
        ]
    }
];

const socialLinks = [
    {
        title: 'Instagram',
        href: 'https://www.instagram.com/seclockofficial/?hl=en',
        icon: 'IconInstagram',
    },
    {
        title: 'Facebook',
        href: 'https://www.facebook.com/seclockofficial/',
        icon: 'IconFacebook',
    },
    {
        title: 'X',
        href: 'https://twitter.com/seclock',
        icon: 'IconX',
    },
    {
        title: 'LinkedIn',
        href: 'https://www.linkedin.com/company/seclock',
        icon: 'IconLinkedIn',
    },
    {
        title: 'YouTube',
        href: 'https://www.youtube.com/channel/UCja3JyZ9S8bX6QteouDFwCg',
        icon: 'IconYouTube',
    }
];
</script>

<template>
    <section class="flex flex-col gap-4 text-gray-400 mb-4 at1024:mb-8 at600:flex-row at600:items-center at600:justify-between">
        <div class="flex items-center justify-start gap-4">
            <DownloadCsvButton
                :is-csv-disabled="isCsvDisabled"
                :is-downloading="isDownloading"
                @csv-export="$emit('csvExport')"
            />
            <div class="flex gap-2 items-center" v-if="computedModel?.size">
                <div class="w-fit">
                    <SeclockMultiselect
                        v-model="computedModel.size"
                        :options="[10, 25, 50]"
                        label="Show"
                        label-position="side"
                        :contrast="true"
                        @change="(event) => {computedModel.size = event;  $emit('change')}"
                    />
                </div>
                <slot name="buttons" />
            </div>
        </div>
        <div>
            <SearchInput
                v-if="!searchHidden"
                v-model="computedModel.query"
                @keydown.enter="() => $emit('change')"
            />
        </div>
    </section>
</template>

<script setup>
import {BaseSvg, SearchInput, SeclockMultiselect, LoadingSpinner, LoadingCircle} from "@/Components";
import { computed } from "vue";
import DownloadCsvButton from "@/Components/Modules/DownloadCsvButton.vue";

const props = defineProps(["modelValue", "searchHidden", "isDownloading", "isCsvDisabled"]);
const emit = defineEmits(["update:modelValue", "csvExport", "change"]);

let computedModel = computed({
    // getter
    get() {
        return props.modelValue;
    },
    // setter
    set(newValue) {
        emit("update:modelValue", newValue);
    },
});


function oops(event){
console.log(event)
}
</script>

<template>
    <div class="flex items-center gap-4">
        <div v-if="card && showImage" class="hidden at1024:block w-24 p-3 rounded-md">
            <img class="mx-auto max-w-full object-contain min-h-20 max-h-24" :src="productCardImage(card)" alt="">
        </div>
        <div>
            <div v-if="backordered" class="text-red leading-tight">
                Backordered
            </div>
            <div class="flex items-center group">
                <span v-if="disabled" class="font-medium text-red">{{ linkText }}</span>
                <Link
                    v-if="routeParams"
                    :href="route(routeName ? routeName : route().current(), routeParams)"
                    class="text-lg hover:underline hover:underline-offset-4 hover:decoration-dotted"
                    :class="linkColor"
                    :disabled="disabled"
                >
                    {{ linkText }}
                </Link>
                <span v-else class="text-lg" :class="linkColor">
                    {{ linkText }}
                </span>
                <button
                    v-if="event.enabled && !disabled"
                    :disabled="disabled"
                    @click="
                        () => {
                            eventBus.emit(event.name, {
                                event: $event,
                                data: event.data,
                            });
                        }
                    "
                    class="invisible group-hover:visible opacity-50 hover:opacity-100"
                >
                    <BaseSvg :name="icon" />
                </button>
            </div>
            <div v-if="backordered && backordered.record_type === 'R' && backordered.order_class === 'D'" class="mt-1 leading-tight">
                Qty {{ backordered.quantity_recieved }} drop-shipped <span v-if="backordered.recieved_date"><date-format :date="backordered.recieved_date"></date-format></span>
            </div>

            <div v-if="backordered && backordered.record_type === 'O' &&  backordered.quantity_linked > 0" class="mt-1 leading-tight">
                Qty {{ backordered.quantity_linked }} ordered from vendor on <date-format :date="backordered.ordered_date"></date-format>
            </div>

            <div v-if="line.notes?.length" class="mt-1 type-secondary leading-tight">
                Notes:
                <template v-for="(note, index) in line.notes">
                    {{ note.text }}
                    <template v-if="index !== line.notes.length - 1">
                        &nbsp;|&nbsp;
                    </template>
                </template>
            </div>
            <button
                @click.stop.prevent="downloadCutSheet"
                v-if="line.is_web"
                class="font-medium mt-2 type-tertiary text-sm underline underline-offset-4 hover:type-primary"
            >
                Cut Sheet
            </button>
        </div>
    </div>
</template>
<script setup>
import { computed } from "vue";
import { Link } from "@inertiajs/vue3";
import {BaseSvg, DateFormat} from "@/Components";
import {eventBus} from "@/Composables";
import _ from "lodash";

const props = defineProps({
    linkColor: {
        type: String,
        default: "type-primary font-bold",
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    routeName: {
        type: String,
        default: false,
    },
    routeParams: {
        type: Array,
        default: () => [],
    },
    linkText: {
        type: String,
        default: "—",
    },
    linkDescription: {
        type: String,
        default: false,
    },
    icon: {
        type: String,
        default: "icon-preview",
    },
    line: {
        type: Object,
        required: true,
    },
    event: {
        type: [Object],
        default: () => {
            return {
                name: "preview",
                data: {},
                enabled: false,
            };
        },
    },
    card: {
        type: Object,
        required: false,
    },
    showImage: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const backordered = computed(() => {
    return _.get(props.line,'backordered',false);
});

const downloadCutSheet = () => {
    window.location = route("web.catalog.products.cut-sheet.download", {
        slug: props.line.slug,
    });
};
</script>

<template>
    <component
        :is="display"
        :pin="pin"
        :invert="invert"
        :round="round"
        @onPin="onPin"
        @toggle_pin="toggle_pin"
        @showPinMenu="showPinMenu"
    />
</template>

<script setup>
import { reactive, computed, inject, onMounted, onUnmounted } from "vue";
import {
    eventBus,
    useAlerts,
    useNavigation,
    usePinsDrawer,
    useTrayMoveDialog,
    useUser,
} from "@/Composables";
import SourcingPin from "../../Themes/Sourcing/Components/Modules/Pin.vue";
import MarketingPin from "../../Themes/Marketing/Components/Modules/Pin.vue";

//COMPOSABLES
const { settings } = useUser();
const { storeAlert } = useAlerts();
const { toggleMenu } = useNavigation();
const { fetchPin, togglePin, removePin, openDrawerTray } = usePinsDrawer();
const { toggleTrayMoveDialog } = useTrayMoveDialog();

const route = inject("route");

//PROPS
const is_source_mode = settings.value.is_sourcing_mode;
const display = computed(() => is_source_mode ? SourcingPin : MarketingPin);
const props = defineProps({
    //This is the ID of the item being pinned (pinnable_id)... NOT the pin id.
    id: {
        type: [String, Number],
        required: true,
        default: null,
    },
    type: {
        type: String,
        required: true,
        default: "item",
    },
    on_icon: {
        type: String,
        required: false,
    },
    off_icon: {
        type: String,
        required: false,
    },
    on_text: {
        type: String,
        required: false,
    },
    off_text: {
        type: String,
        required: false,
    },
    custom: {
        type: String,
        required: false,
        default: "",
    },
    label: {
        type: Boolean,
        required: false,
        default: false,
    },
    modalButton: {
        type: Boolean,
        required: false,
        default: false,
    },
    rotate: {
        type: Boolean,
        required: false,
        default: false,
    },
    more: {
        type: Boolean,
        required: false,
        default: false,
    },
    invert: {
        type: Boolean,
        required: false,
        default: false,
    },
    round: {
        type: Boolean,
        required: false,
        default: false
    },
});

//REACTIVE
const pin = reactive({
    //data
    data: null,
    state: {
        initializing: true,
        toggled: false,
        pinned: false,
        processing: false,
    },

    //props
    more: props.more,
    custom: props.custom,
    modalButton: props.modalButton,
    label: props.label,
    rotate: props.rotate,

    //computed props
    icon: computed(() => {
            return pin.state.pinned
                ? (props.on_icon ? props.on_icon : (is_source_mode ? "icon-push-pin" : "icon-heart-filled"))
                : (props.off_icon ? props.off_icon : (is_source_mode ? "icon-push-pin-outlined" : "icon-heart-outline"))
        }),
    text: computed(() => {
            return pin.state.pinned
                ? (props.on_text ? props.on_text : (is_source_mode ? "Remove from Pins" : "Remove from Favorites"))
                : (props.off_text ? props.off_text : (is_source_mode ? "Add to Pins" : "Add to Favorites"))
        }),
    options: computed(() =>
        [
            [
                {
                    value: "Move...",
                    label: "Move...",
                    action: (event) => {
                        toggleTrayMoveDialog(event, {
                            pinnable_id: pin.data.pinnable_id,
                            pin_type: pin.data.type,
                        });
                    },
                },
                {
                    value: "Open pins list",
                    label: "Open pins list",
                    action: (event) => {
                        openDrawerTray({ event: event, pin: pin.data });
                    },
                },
                {
                    value: "Remove pin",
                    label: "Remove pin",
                    class: "!text-scarlet",
                    action: () => {
                        pin.state.processing = true;
                        removePin(props.type, props.id);
                    },
                },
            ],
        ]),
});

//Event listeners methods
function onPinFetched(fetchedPin) {
    if (fetchedPin && parseInt(fetchedPin.pinnable_id) === parseInt(props.id)) {
        pin.data = fetchedPin;
        pin.state.pinned = true;
    } else if (pin.state.initializing) {
        pin.state.pinned = false;
    }
    pin.state.initializing = false;
}

function onPinRemoved(removedPin) {
    if (props.id === removedPin.pinnable_id && props.type === removedPin.pin_type) {
        pin.state.pinned = false;
        pin.state.processing = false;
    }
}

function onPinToggledOn(toggledPin) {
    if (parseInt(props.id) === parseInt(toggledPin.pinnable_id) && props.type === toggledPin.type) {
        pin.data = toggledPin;
        pin.state.pinned = true;
        pin.state.processing = false;
        if (pin.state.toggled) {
            toggleMenu("mini-pins", event, false);
            pin.state.toggled = false;
        }
    }
}

function onPinToggledOff(toggledPin) {
    if (parseInt(props.id) === parseInt(toggledPin.pinnable_id) && props.type === toggledPin.type) {
        pin.state.pinned = false;
        pin.state.toggled = false;
        pin.state.processing = false;
    }
}

function onPinToggledError(toggledPin) {
    if (parseInt(props.id) === parseInt(toggledPin.pinnable_id) && props.type === toggledPin.type) {
        pin.state.processing = false;
    }
}

//METHODS
function showPinMenu(event) {
    toggleMenu("pin-options", event, false);
}

function toggle_pin() {
    pin.state.processing = true;
    togglePin(props.type, props.id)
}

function refresh() {
    fetchPin(props.type, props.id); //id is the pinnable_id
}

function onPin(event) {
    pin.state.toggled = true;
    toggle_pin();
}

//MOUNTED
onMounted(() => {
    eventBus.on('pins:pin-fetched', onPinFetched);
    eventBus.on('pins:pin-removed', onPinRemoved);
    eventBus.on('pins:pin-toggled-on', onPinToggledOn);
    eventBus.on('pins:pin-toggled-off', onPinToggledOff);
    eventBus.on('pins:pin-toggled-error', onPinToggledError);

    //Load the initial Pin state
    setTimeout(()=>{
        refresh();
    },200)
});

onUnmounted(() => {
    eventBus.off('pins:pin-fetched', onPinFetched);
    eventBus.off('pins:pin-removed', onPinRemoved);
    eventBus.off('pins:pin-toggled-on', onPinToggledOn);
    eventBus.off('pins:pin-toggled-off', onPinToggledOff);
    eventBus.off('pins:pin-toggled-error', onPinToggledError);
});
</script>

<style scoped>
.pink-fade {
    background-color: rgba(249, 168, 212, 0.2);
}
</style>

<template>
    <div
        class="bg-gray-tier-2 border-b border-keyline-primary sticky flex top-[var(--app-header-height)] w-full z-30"
    >
        <div class="flex items-center pl-1 w-72 border-r border-keyline-primary">
            <Link
                :href="route('web.account.projects.index')"
                class="flex-none mr-1.5"
            >
                <base-svg name="icon-chevron-left" />
            </Link>
            <div class="flex-1 min-w-0 relative" v-click-outside="closeOptions">
                <button
                    class="group w-full flex items-center font-medium max-w-full pr-1"
                    @click="state.showOptions = !state.showOptions"
                >
                    <project-icon class="flex-none mr-2" :project="project" />
                    <span class="truncate min-w-0">{{ project.label }}</span>
                    <base-svg
                        name="icon-expand-more"
                        class="ml-auto mr-2 flex-none group-hover:type-primary text-gray-tier-7"
                        :class="{ 'rotate-180': state.showOptions }"
                    />
                </button>
                <transition name="search-pop">
                    <action-dropdown
                        v-show="state.showOptions"
                        :options="projectOptions"
                        :offset="{ top: 2, left: 0 }"
                    />
                </transition>
            </div>
        </div>
        <nav class="grow">
            <ul class="flex gap-6 px-6 font-medium">
                <li v-for="(link, i) in links" :key="i">
                    <Link
                        :href="route(link.route, project)"
                        class="block pt-2.5 pb-3 border-b-2 hover:opacity-100 transition-opacity"
                        :only="['component', ...link.only]"
                        :class="{
                            'border-royal': isActive(link, $page),
                            'border-transparent opacity-70': !isActive(
                                link,
                                $page
                            ),
                        }"
                        @click="state.preloading = link.route"
                    >
                        {{ link.title }}
                    </Link>
                </li>
            </ul>
        </nav>
        <div
            class="flex flex-col gap-1 justify-center items-end py-1 px-6"
            v-if="project"
        >
            <div class="flex items-baseline gap-2">
                <div class="mr-3 text-sm type-secondary" v-if="isEmployee()">
                    {{ project.team.customer_reference.customer_id }} • {{ project.team.customer_reference.erp_record.name }}
                </div>
                <span
                    class="text-gray-tier-7 text-sm font-bold"
                    v-if="!project.meta.budget"
                    >Project Total:</span
                >
                <price
                    :value="project?.total"
                    :show-currency="true"
                    :allow-zero="true"
                    class="text-sm font-medium"
                />
            </div>
            <div
                class="bg-gray-tier-5 overflow-hidden rounded-full w-48 h-1.5"
                v-if="project.meta.budget"
            >
                <div
                    class="h-full min-w-[12px] transition-all duration-500"
                    :class="{
                        'bg-apple': percentSpent <= 100,
                        'bg-amber': percentSpent > 100,
                    }"
                    :style="`width: ${percentSpent}%`"
                ></div>
            </div>
        </div>
    </div>
    <div :class="{ 'is-preloading': state.preloading }">
        <slot></slot>
    </div>
</template>

<script setup>
import {
    ActionDropdown,
    BaseSvg,
    ProjectCreateEditModal,
    ProjectDeleteModal,
    ProjectDuplicateModal,
    ProjectExportModal,
    ProjectIcon,
    ProjectPurchaseModal,
    ProjectQuoteModal,
} from "@/Components";
import { eventBus, useHelpers, useModalDialog, useUser } from "@/Composables";

import { computed, inject, reactive } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import Price from "../../../Components/Modules/Price.vue";

const { toggleModal } = useModalDialog();

const route = inject("route");

const project = computed(() => usePage().props.project);
const component = computed(() => usePage().props.component);

const {user, isEmployee} = useUser();

const state = reactive({
    showOptions: false,
    preloading: null,
    exportingQr: false,
});

router.on("navigate", () => {
    state.preloading = null;
});

eventBus.on('preload', (route) => {
    state.preloading = route
})

const percentSpent = computed(() => {
    const budget = project.value.meta.budget || 0;
    const spent = project.value.total || 0;

    if (!budget) return 0;

    return (spent / budget) * 100;
});

const links = [
    {
        title: "Overview",
        route: "web.account.projects.show",
        component: "Account/Projects/Show",
        only: ["referer", "flash"],
    },
    {
        title: "Hardware",
        route: "web.account.projects.hardware.index",
        component: "Account/Projects/Hardware",
        only: ["referer", "flash"],
    },
    {
        title: "Sets",
        route: "web.account.projects.sets.index",
        component: "Account/Projects/Sets",
        only: ["referer", "set","flash"],
    },
    {
        title: "Openings",
        route: "web.account.projects.openings.index",
        component: "Account/Projects/Openings",
        only: ["referer", "opening","flash"],
    },
    {
        title: "Activity",
        route: "web.account.projects.activity.index",
        component: "Account/Projects/Activity",
        only: ["referer", "activity","flash"],
    },
];

const projectOptions = computed(() => [
    [
        {
            label: "Export/Print",
            value: "exportProject",
            icon: "file-download",
            action: ($event) => {
                closeOptions();
                toggleModal(
                    ProjectExportModal,
                    { $event },
                    { project: project.value }
                );
            },
        },
        {
            label: "Purchase",
            value: "purchase",
            icon: "shopping-cart",
            action: ($event) => {
                toggleModal(
                    ProjectPurchaseModal,
                    { $event },
                    { project: project.value }
                );
            },
        },
        {
            label: "Create Quote",
            value: "purchase",
            icon: "description",
            action: ($event) => {
                toggleModal(
                    ProjectQuoteModal,
                    { $event },
                    { project: project.value }
                );
            },
        },
        {
            label: "Download QR Codes",
            value: "downloadQrCodes",
            icon: state.exportingQr ? 'loading-circle' : 'qr-code',
            visible: project.value.openings.length,
            action: () => {
                state.exportingQr = true
                router.post(route('web.account.projects.export.qr', project.value), {}, {
                    onFinish: () => {
                        state.exportingQr = false
                    }
                })
            },
        },
    ],
    [
        {
            label: "Edit project details",
            value: "editProject",
            icon: "edit",
            action: ($event) => {
                closeOptions();
                toggleModal(ProjectCreateEditModal, $event, {
                    project: project.value,
                    editing: true,
                });
            },
        },
        {
            label: "Copy share link",
            value: "copyShareLink",
            icon: "link",
            action: copy,
        },
        {
            label: "Duplicate",
            value: "duplicate",
            icon: "content-copy",
            action: ($event) => {
                closeOptions();
                toggleModal(ProjectDuplicateModal, $event, {
                    project: project.value,
                });
            },
        },
    ],
    (project.value.docs || []).map((asset) => ({
        label: asset.name,
        link: true,
        route: route('web.account.projects.documents.show', { project: project.value, asset }),
    })),
    [
        {
            label: "Delete project",
            value: "delete",
            icon: "trash-can",
            action: ($event) => {
                closeOptions();
                toggleModal(ProjectDeleteModal, $event, {
                    project: project.value,
                });
            },
        },
    ],
].filter(group => group.length));

function copy($event) {
    let confirmationText = "Copied!";
    let confirmationDuration = 1500;
    let confirmationElement = $event.currentTarget.querySelector("span");

    clearTimeout(confirmationElement.confirmationTimer);
    useHelpers().copyUrl();

    if (!confirmationElement.dataset.initialText) {
        confirmationElement.dataset.initialText = confirmationElement.innerText;
    }

    confirmationElement.innerText = confirmationText;
    confirmationElement.confirmationTimer = setTimeout(() => {
        confirmationElement.innerText = confirmationElement.dataset.initialText;
    }, confirmationDuration);
}

function closeOptions() {
    state.showOptions = false;
}

function isActive(link, $page) {
    if (state.preloading) {
        return state.preloading === link.route;
    }

    return component.value.startsWith(link.component);
}
</script>

<template>
    <component :is="layout">
        <component :is="sub_layout" v-if="sub_layout">
            <slot></slot>
        </component>
        <slot v-else />
    </component>
    <teleport to="#dialogs">
        <alerts />
        <dialogs />
        <overlay />
        <underlay />
        <Transition name="fade">
            <LoadingOverlay v-if="transitioning" />
        </Transition>
    </teleport>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
<script setup>
import {computed, onUpdated} from "vue";
import { useGlobalEventListeners, useUser, useIubenda } from "@/Composables";
import {
    Alerts,
    Dialog as Dialogs,
    LoadingOverlay,
    Overlay,
    Underlay,
} from "@/Components";
import { usePage } from "@inertiajs/vue3";
import * as layouts from "@/Layouts/Modules/Layouts";
import * as sub_layouts from "@/Layouts/Modules/SubLayouts";
import eventBus from "@/Composables/Modules/eventBus";

//register event listeners
useGlobalEventListeners();

const { transitioning } = useUser();

const layout = computed(() => {
    return layouts[usePage().props.layout] ?? layouts["MarketingLayout"];
});

const sub_layout = computed(() => {
    return sub_layouts[usePage().props.sub_layout] ?? false;
});

onUpdated(() => eventBus.emit("layout:updated"));


</script>

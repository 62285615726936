<template>
    <AppModal
        :title="
            data?.title ??
            (data?.editing ? 'Update project' : 'Create new project')
        "
        :submitText="data?.editing ? 'Update Project' : 'Create Project'"
        @submit="onSubmit"
        @close="() => forceClose()"
    >
        <template #content>
            <fieldset class="border-3d-b p-6 pb-4 space-y-4">
                <TeamSelector
                    v-if="!data?.editing && isEmployee()"
                    v-model="form.team_id"
                    label="Customer"
                    :error="form.errors?.team_id"
                />
                {{}}
                <div v-if="data?.editing &&  isEmployee()">
                    <div class="text-sm leading-tight">

                        <h4 class="type-primary font-medium">
                            {{ data.project.team.customer_reference.customer_id }} • {{ data.project.team.customer_reference.erp_record.name }}
                        </h4>
                        <p class="type-secondary text-xs">{{ data.project.team.name }}</p>
                    </div>
                </div>

                <div class="flex gap-4 -mt-2">
                    <AppInput
                        type="text"
                        v-model="form.label"
                        :error="form.errors?.label"
                        label="Project Name"
                        placeholder="e.g. Athena Hotel"
                        :required="true"
                        :autofocus="true"
                        class="grow"
                    />
                    <fieldset>
                        <legend class="text-sm type-secondary py-2">
                            Color
                        </legend>
                        <div class="inline-flex gap-2">
                            <label v-for="color in projectColors">
                                <input
                                    type="radio"
                                    name="color"
                                    v-model="form.options.color"
                                    :value="color.value"
                                    class="peer sr-only"
                                />
                                <span
                                    class="w-6 h-6 rounded-full flex items-center justify-center outline-offset-2 outline-2"
                                    :class="{
                                        [color.class]: true,
                                        outline:
                                            form.options.color === color.value,
                                        'peer-focus:outline peer-focus:outline-royal': true,
                                    }"
                                >
                                    <BaseSvg
                                        name="icon-check"
                                        class="w-4 h-4 text-white"
                                        v-if="
                                            form.options.color === color.value
                                        "
                                    />
                                </span>
                                <span class="sr-only">{{ color.label }}</span>
                            </label>
                        </div>
                        <ValidationError :text="form.errors['options.color']" />
                    </fieldset>
                </div>
                <AppInput
                    type="textarea"
                    v-model="form.meta.description"
                    :error="form.errors['meta.description']"
                    label="Description"
                    placeholder="e.g. Athena Hotel"
                    maxlength="200"
                />
                <div class="w-full grid grid-cols-2 gap-4 items-end">
                    <AppInput
                        type="date"
                        v-model="form.meta.bid_date"
                        :error="form.errors['meta.bid_date']"
                        label="Bid date"
                    />
                    <AppInput
                        type="number"
                        v-model="form.meta.budget"
                        :error="form.errors['meta.budget']"
                        label="Budget"
                        icon="attach-money"
                    />
                </div>

                <div v-if="!data.project || data.project.user_id === user.id">
                    <div class="flex items-center justify-between">
                        <span class="flex flex-col">
                            <span class="text-sm">Set as private</span
                            ><span class="sr-only">.</span>
                            <span
                                class="text-xs text-gray-625 dark:text-gray-350"
                                >Projects are normally visible to all members of your organization. Marking it as private will mean it is only visible to you.</span
                            >
                        </span>
                        <toggle
                            label="Set as private"
                            v-model="form.is_private"
                        />
                    </div>
                    <ValidationError
                        :text="form.errors?.is_private"
                        class="mt-1"
                    />
                </div>
            </fieldset>
            <div v-show="showAdditionalDetails">
                <fieldset class="px-6 pt-2 pb-6 space-y-4">
                    <AppInput
                        label="Job reference number"
                        type="text"
                        v-model="form.meta.job_reference_number"
                        :error="form.errors['meta.job_reference_number']"
                    />
                    <AppInput
                        label="Architect"
                        type="text"
                        v-model="form.meta.architect"
                        :error="form.errors['meta.architect']"
                    />
                    <AppInput
                        label="General contractor"
                        type="text"
                        v-model="form.meta.general_contractor"
                        :error="form.errors['meta.general_contractor']"
                    />
                    <div class="w-full grid grid-cols-2 gap-4 items-end">
                        <AppInput
                            label="Starts on"
                            type="date"
                            v-model="form.meta.start_date"
                            :error="form.errors['meta.start_date']"
                        />
                        <AppInput
                            label="Ends on"
                            type="date"
                            v-model="form.meta.end_date"
                            :error="form.errors['meta.end_date']"
                        />
                    </div>
                </fieldset>
                <fieldset
                    class="border-3d-t px-6 py-4 space-y-4"
                    v-if="false && !data?.editing"
                >
                    <div class="border-3d-b pb-6">
                        <h4 class="font-medium text-sm">Create sets</h4>
                        <div class="flex gap-4 items-end mt-1.5">
                            <AppInput
                                label="Qty"
                                type="number"
                                v-model="form.sets_quantity"
                                class="w-24"
                                :hide-optional="true"
                            />
                            <SeclockMultiselect
                                class="w-full"
                                v-model="form.sets_naming"
                                name="sortBy"
                                :options="bulkNamingConventionOptions"
                                :option-is-object="true"
                                :large="true"
                                :label="$t('Default naming convention')"
                                :can-clear="false"
                            />
                        </div>
                    </div>
                    <div>
                        <h4 class="font-medium text-sm">Create openings</h4>
                        <div class="flex gap-4 items-end mt-1.5">
                            <AppInput
                                label="Qty"
                                type="number"
                                v-model="form.openings_quantity"
                                class="w-24"
                                :hide-optional="true"
                            />
                            <SeclockMultiselect
                                class="w-full"
                                v-model="form.openings_naming"
                                name="sortBy"
                                :options="bulkNamingConventionOptions"
                                :option-is-object="true"
                                :large="true"
                                :label="$t('Default naming convention')"
                                :can-clear="false"
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
            <fieldset class="px-6 py-4">
                <!-- Accessibility / JS note:
                1. when this button is clicked the first time, please set
                   focus on the first hidden input (#job-reference-number)
                2. when this button is clicked a second time, please
                   leave focus on itself (this should already happen
                   natively) -->
                <button
                    type="button"
                    class="text-xs"
                    @click="showAdditionalDetails = !showAdditionalDetails"
                >
                    <span v-show="!showAdditionalDetails">
                        Show additional project details
                        <BaseSvg
                            name="icon-expand-more"
                            class="h-5 w-5"
                            aria-hidden="true"
                        />
                    </span>
                    <span v-show="showAdditionalDetails">
                        Hide additional project details
                        <BaseSvg
                            name="icon-expand-less"
                            class="h-5 w-5"
                            aria-hidden="true"
                        />
                    </span>
                </button>
            </fieldset>
        </template>
    </AppModal>
</template>
<script setup>
import { v1 as uuidv1 } from 'uuid'
import {
    AppInput,
    AppModal,
    BaseSvg,
    FileUpload,
    SeclockMultiselect,
    TeamSelector,
    Toggle,
    ValidationError,
} from '@/Components'
import { eventBus, useDialog, useUser } from '@/Composables'
import { useForm } from '@inertiajs/vue3'
import {inject, onMounted, ref, watch} from 'vue'


const id = uuidv1();
const { forceClose } = useDialog();
const { user, isEmployee } = useUser();
const { data } = defineProps({
    data: {
        type: Object,
        required: true,
        default: () => {},
    },
});

onMounted(() => {
    eventBus.on("form:reset", () => form.reset());
});

const bulkNamingConventionOptions =
    inject("DynamicSettings").project.naming_conventions;

const form = useForm({
    team_id: data.project?.team_id ?? null,
    label: data.project?.label ?? "",
    is_private: data.project?.is_private ?? false,
    options: {
        color: data.project?.options.color ?? "gray",
    },
    meta: {
        description: data.project?.meta.description ?? null,
        bid_date: data.project?.meta.bid_date ?? null,
        budget: data.project?.meta.budget ?? null,
        job_reference_number: data.project?.meta.job_reference_number ?? null,
        architect: data.project?.meta.architect ?? null,
        general_contractor: data.project?.meta.general_contractor ?? null,
        start_date: data.project?.meta.start_date ?? null,
        end_date: data.project?.meta.end_date ?? null,
    },
    sets_quantity: 0,
    sets_naming: bulkNamingConventionOptions[0]?.value,
    openings_quantity: 0,
    openings_naming: bulkNamingConventionOptions[0]?.value,
    docs: [],
});

let route = inject("route");
let showAdditionalDetails = ref(data.showAdditionalDetails ?? false);

const projectColors = [
    { value: "gray", label: "Gray", class: "bg-project-gray" },
    { value: "blue", label: "Blue", class: "bg-project-blue" },
    { value: "lavender", label: "Lavender", class: "bg-project-lavender" },
    { value: "orange", label: "Orange", class: "bg-project-orange" },
    { value: "avocado", label: "Avocado", class: "bg-project-avocado" },
];

watch(showAdditionalDetails, async (visible) => {
    if (!visible) {
        form.reset([
            "meta.job_reference_number",
            "meta.architect",
            "meta.general_contractor",
            "meta.start_date",
            "meta.end_date",
            "sets_quantity",
            "sets_naming",
            "openings_quantity",
            "openings_naming",
            "docs",
        ]);
    }
});

function onSubmit(resolve, reject) {
    // Clear error messages before re-submitting
    form.clearErrors();

    const callbacks = {
        onSuccess: resolve,
        onError: reject,
        onFinish: () => eventBus.emit("dashboard:reload-card", 'project_card')

    };

    if (data?.editing) {
        form.put(route("web.account.projects.update", data.project), {
            only: ["referer", "project", "errors", "component"],
            ...callbacks,
        });
    } else {
        form.post(route("web.account.projects.store"), {
            ...callbacks,
        });
    }
}
</script>
